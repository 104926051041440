import {
    NavLink
  } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

const CustomNavbar = ({ pageInfo }) => {

  const { logout, user } = useAuth0();

  return(
    <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
      <div className="container">
        <a className="navbar-brand" href="#">Onzen</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
          <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
            <li className="nav-item">
                <NavLink to="/dashboard" className="nav-link" activeClassName="active">Dashboard</NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/alerts" className="nav-link" activeClassName="active">Alerts</NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/analytics" className="nav-link" activeClassName="active">Analytics</NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/integrations" className="nav-link" activeClassName="active">Integrations</NavLink>
            </li>
          </ul>
          <form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
            <input type="search" className="form-control" placeholder="Search..." aria-label="Search"/>
          </form>
          <div className="dropdown text-end">
            <a href="/" className="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
              <img src={user.picture} alt={user.name} width="32" height="32" className="rounded-circle"/>
            </a>
            <ul className="dropdown-menu dropdown-menu-end text-small" aria-labelledby="dropdownUser1">
              <li><h6 className="dropdown-header">Signed in as</h6></li>
              <li>
                <NavLink to="/profile" className="dropdown-item">
                    {user.name}
                </NavLink>
              </li>
              <li><hr className="dropdown-divider"/></li>
              <li>
                <NavLink to="/profile" className="dropdown-item">
                    Your profile
                </NavLink>
              </li>
              <li>
                <a className="dropdown-item" href="#" onClick={() => logout({ returnTo: window.location.origin })}>
                  Log out
                </a>
              </li>
            </ul>
        </div>
      </div>
    </nav>
  );
};

export default CustomNavbar
