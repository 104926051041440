import React from "react";
import Layout from '../components/layout'
import Stats from '../components/stats'
import DataService from '../services/dataService'


const AlertTable = ({items}) => (
  <div className="table-responsive">
    <table className="table">
      <thead className="table-light">
        <tr>
          <th scope="col">Detector</th>
          <th scope="col">Active alerts</th>
          <th scope="col">Last update</th>
          <th scope="col">Options</th>
        </tr>
      </thead>
      <tbody>
        { items.map((item, index) => (
          <tr key={index}>
            <td>
              <p className="fw-bold">{item['name']}</p>
              {item['description']}
            </td>
            <td style={{}}>
              <a className="btn btn-danger">2</a>&nbsp;
              <a className="btn btn-outline-warning">0</a>&nbsp;
              <a className="btn btn-outline-info">0</a>
            </td>
            <td>
              {item['last_update']}
            </td>
            <td>
              <div className="dropdown">
                <button className="btn btn-light btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <span className="fas fa-cog"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a className="dropdown-item" href="#">Edit</a></li>
                  <li><a className="dropdown-item" href="#">Mute</a></li>
                </ul>
              </div>
              {/*                             <div className="form-check form-switch"> */}
              {/*                               <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/> */}
              {/*                               <label className="form-check-label" for="flexSwitchCheckDefault">Enable</label> */}
              {/*                             </div> */}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

class Alerts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      alerts: [],
    };
  }

  async componentDidMount() {
    const alerts = await DataService.getDetectors();
    this.setState({ alerts });
  }

  render() {
    return (
     <Layout>
         <header className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-3 mb-4 border-bottom">
             <h1 className="h2">Alerts</h1>
             <div className="btn-toolbar mb-2 mb-md-0">
               <div className="btn-group me-2">
                 <button type="button" className="btn btn-sm btn-outline-secondary">Share</button>
                 <button type="button" className="btn btn-sm btn-outline-secondary">Export</button>
               </div>
               <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
                 <span data-feather="calendar"></span>
                 This week
               </button>
             </div>
         </header>

       <main>
           <div className="row">
             <div className="col-xl-3 col-sm-6 col-12 mb-4">
               <Stats/>
             </div>
             <div className="col-xl-3 col-sm-6 col-12 mb-4">
               <Stats></Stats>
             </div>
             <div className="col-xl-3 col-sm-6 col-12 mb-4">
               <Stats></Stats>
             </div>
             <div className="col-xl-3 col-sm-6 col-12 mb-4">
               <Stats></Stats>
             </div>
           </div>
           <AlertTable items={this.state.alerts}></AlertTable>
       </main>
     </Layout>
   );
  }
}

export default Alerts;
