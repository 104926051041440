import React from "react";
import Layout from '../components/layout'

import Loading from "../components/loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

export const Profile = () => {
  const { user } = useAuth0();

  return (
    <Layout>
      <div className="container mb-5">
        <div className="align-items-center profile-header mb-5 text-center text-md-left">
          <div md={2}>
            <img
              src={user.picture}
              alt="Profile"
              className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
            />
          </div>
          <div md>
            <h2>{user.name}</h2>
            <p className="lead text-muted">{user.email}</p>
          </div>
        </div>
        <div>
          <div>{JSON.stringify(user, null, 2)}</div>
        </div>
      </div>
    </Layout>
  );
};

export default withAuthenticationRequired(Profile, {
  onRedirecting: () => <Loading />,
});
