import React from "react";

const Footer = () => (
  <footer className="blog-footer">
    <p>Onzen | v0.1</p>
    <p>
      <a href="#">Back to top</a>
    </p>
  </footer>
)

export default Footer
