import Alerts from './pages/alerts'
import Profile from './pages/profile'
import Dashboard from './pages/dashboard'
import Analytics from './pages/analytics'
import Integrations from './pages/Integrations'
import ReactGA from 'react-ga';

import {
  Switch
} from "react-router-dom";

import Loading from "./components/loading";
import { useAuth0 } from "@auth0/auth0-react";
import ProtectedRoute from "./auth/protected-route";

function App() {
  ReactGA.initialize('UA-38003101-1');

  const { isLoading, error, isAuthenticated } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
      <Switch>
          <ProtectedRoute exact path="/" component={Dashboard}/>
          <ProtectedRoute path="/dashboard" component={Dashboard}/>
          <ProtectedRoute path="/alerts" component={Alerts}/>
          <ProtectedRoute path="/analytics" component={Analytics} />
          <ProtectedRoute path="/profile" component={Profile} />
          <ProtectedRoute path="/integrations" component={Integrations} />
          <ProtectedRoute component={Dashboard} />
      </Switch>
  );
}

export default App;
