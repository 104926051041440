
import DETECTORS from '../data/detectors.json'
import ALERTS from '../data/alerts.json'
import INTEGRATIONS from '../data/integrations.json'

class DataService {

  static resource(name) {
      const serviceLayerUrl = 'https://saphana.htri.net:50000/b1s/v1/';
      return serviceLayerUrl + name;
  }

  static json(fetchPromise) {
    return fetchPromise.then(response => {
      if(response.ok) {
        return response.json();
      } else {
        console.log('error getting json from response:', response);
        throw response.statusText;
      }
    });
  }

  static getIntegrations() {
    return Promise.resolve(INTEGRATIONS);
  }

  static getAlerts() {
    return Promise.resolve(ALERTS);
  }

  static getDetectors() {
    return Promise.resolve(DETECTORS);
  }

  static get(name) {
    var url = this.resource(name);
    return this.json(fetch(url, {
          credentials: 'include'
    }));
  }
}
export default DataService;
