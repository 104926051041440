
import React from "react"
import Footer from "./footer"
import Navbar from "./navbar"

const Layout = ({ children }) => (
  <>
    <Navbar title="Title" location="{location}" />
      <div className="container">
        {children}
      </div>
    <Footer title="Title" author="Author" />
  </>
)

export default Layout
