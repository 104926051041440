import React from "react";
import Layout from '../components/layout'
import Card from '../components/card'
import Stats from '../components/stats'
import BarChart from '../components/barchart'
import RadarChart from '../components/charts/RadarChart'
import VerticalBar from '../components/charts/VerticalBarChart'

export const Dashboard = () => {

  return (
    <Layout>
      <header className="py-3 mb-4 border-bottom">
          <h1 className="h2">Dashboard</h1>
      </header>
      <main>
        <div className="row">
          <div className="col-xl-3 col-sm-6 col-12 mb-4">
            <Stats
              title="Members"
              number="23"
              icon="user"
            />
          </div>
          <div className="col-xl-3 col-sm-6 col-12 mb-4">
            <Stats
              title="Teams"
              number="5"
              icon="users"
            />
          </div>
          <div className="col-xl-3 col-sm-6 col-12 mb-4">
            <Stats
              title="Teams"
              number="5"
              icon="users"
            />
          </div>
          <div className="col-xl-3 col-sm-6 col-12 mb-4">
            <Stats
              title="Teams"
              number="5"
              icon="users"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <Card title="Report 1">
              <VerticalBar/>
            </Card>
          </div>
          <div className="col-lg-6">
            <Card title="Latest alerts">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">First</th>
                    <th scope="col">Last</th>
                    <th scope="col">Handle</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td colspan="2">Larry the Bird</td>
                    <td>@twitter</td>
                  </tr>
                </tbody>
              </table>
            </Card>
            <Card title="Pulse">

            </Card>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Dashboard
