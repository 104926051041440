import React from "react";
import placeholder from './../assets/placeholder.svg';

const IntegrationCard = ({ title, subtitle, description, children, style }) => (
  <div className="card" style={style}>
    <img className="card-img-top" src={placeholder} alt="Card cap"/>
    <div className="card-body">
      <h5 className="card-title">{title}</h5>
      <h6 className="card-subtitle mb-2 text-muted">{subtitle}</h6>
      <p className="card-text">{description}</p>
      {children}
      <a href="#" className="btn btn-primary">Connect</a>
    </div>
  </div>
);

export default IntegrationCard;
