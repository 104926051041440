import React from "react";

const Stats = ({title, number, icon, style}) => (
  <div className="card" style={style}>
    <div className="card-body">
      <div className="d-flex justify-content-between px-md-1">
        <div>
          <h3 className="text-primary">{number}</h3>
          <p className="mb-0">{title}</p>
        </div>
        <div className="align-self-center">
          <i className={`fas fa-${ icon } text-primary fa-3x`}></i>
        </div>
      </div>
      <div className="px-md-1">
        <div className="progress mt-3 mb-1 rounded" style={{height: "7px"}}>
          <div className="progress-bar bg-primary" role="progressbar" style={{width: "80%"}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </div>
  </div>
);

export default Stats;
