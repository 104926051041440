
import React from 'react';
import { Radar } from 'react-chartjs-2';

const rand = () => Math.round(Math.random() * 20 - 10);

const data = {
  labels: ['Cycle time', 'Active days', 'Time to open', 'Merged PRs', 'PRs Comments'],
  datasets: [
    {
      label: 'Member 1',
      data: [rand(), rand(), rand(), rand(), rand()],
      backgroundColor: 'rgba(186, 136, 227, 0.2)',
      borderColor: 'rgba(186, 136, 227, 1)',
      borderWidth: 1,
    },
    {
      label: 'Member 2',
      data: [rand(), rand(), rand(), rand(), rand()],
      backgroundColor: 'rgba(186, 136, 227, 0.2)',
      borderColor: 'rgba(186, 136, 227, 1)',
      borderWidth: 1,
    },
    {
      label: 'Average',
      data: [rand(), rand(), rand(), rand(), rand()],
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 1,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    title: {
      display: false,
      text: 'Chart.js Radar Chart'
    }
  },
  scale: {
    ticks: { beginAtZero: true },
  },
};

const RadarChart = () => (
  <>
    <Radar data={data} options={options} />
  </>
);

export default RadarChart;
