import React from "react";
import Layout from '../components/layout'
import Card from '../components/card'
import Stats from '../components/stats'
import BarChart from '../components/barchart'
import RadarChart from '../components/charts/RadarChart'
import VerticalBar from '../components/charts/VerticalBarChart'

export const Analytics = () => {

  return (
    <Layout>
      <header className="py-3 mb-4 border-bottom">
          <h1 className="h2">Analytics</h1>
      </header>
      <main>
        <div className="row">
          <div className="col-xl-3 col-sm-6 col-12 mb-4">
            <Stats
              title="Members"
              number="23"
              icon="user"
            />
          </div>
          <div className="col-xl-3 col-sm-6 col-12 mb-4">
            <Stats
              title="Teams"
              number="5"
              icon="users"
            />
          </div>
          <div className="col-xl-3 col-sm-6 col-12 mb-4">
            <Stats
              title="Teams"
              number="5"
              icon="users"
            />
          </div>
          <div className="col-xl-3 col-sm-6 col-12 mb-4">
            <Stats
              title="Teams"
              number="5"
              icon="users"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <Card title="Report 1">
              <VerticalBar/>
            </Card>
          </div>
          <div className="col-lg-6">
            <Card title="Report 2">
              Hello World
              <p>Hello World</p>
            </Card>
            <Card title="Report 3">
              <RadarChart></RadarChart>
            </Card>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Analytics
