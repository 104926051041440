import React from "react";
import Layout from '../components/layout'
import DataService from '../services/dataService'
import IntegrationCard from '../components/integrationCard'

class Integrations extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      integrations: [],
    };
  }

  async componentDidMount() {
    const integrations = await DataService.getIntegrations();
    this.setState({ integrations });
  }

  render() {
    return (
      <Layout>
        <header className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-3 mb-4 border-bottom">
            <h1 className="h2">Integrations</h1>
        </header>
        <main className="row">
          <div className="row row-cols-1 row-cols-md-3 g-4">
            {this.state.integrations.map((integration, index) => (
              <div key={index} className="col-xl-3 col-sm-6 col-12 mb-4">
                <IntegrationCard
                  title={integration['name']}
                  subtitle={integration['category']}
                  />
              </div>
            ))}
          </div>
        </main>
      </Layout>
    )
  }
}

export default Integrations;
